import React from 'react'
import { Link } from 'gatsby'

export default () => {
  return (
    <React.Fragment>
      <Link to="/blog" activeClassName="active" aria-label="View blog page">
        Blog
      </Link>
      <Link to="/talks" activeClassName="active" aria-label="View talks page">
        Talks
      </Link>
      <Link to="/workshops" activeClassName="active" aria-label="View workshops page">
        Workshops
      </Link>
      <Link to="/about" activeClassName="active" aria-label="View about page">
        About
      </Link>
      <Link to="/contact" activeClassName="active" aria-label="View contact page">
        Contact
      </Link>
    </React.Fragment>
  )
}
